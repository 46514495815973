<template>
  <div class="search text-center position-relative p-2 max-section-width">
    <b-spinner
      v-if="!isLoaded"
      class="m-3 spinner"
      label="Loading..."
    />
    <autocomplete
      v-show="isLoaded"
      ref="search"
      v-focus="true"
      :search="search"
      :value="addressInput"
      :placeholder="$t('searchForProduct')"
      :aria-label="$t('ariaLabel.search')"
      @submit="handleSubmit"
      @blur="lostFocus"
    />
    <b-iconstack
      v-show="isLoaded"
      font-scale="1"
      class="clean position-absolute"
      @click="cleanSearch()"
    >
      <b-icon
        stacked
        icon="x-circle-fill"
        variant="light"
      />
    </b-iconstack>
  </div>
</template>

<script>
/* eslint-disable func-names */
import { focus } from 'vue-focus';
import { mapActions } from 'vuex';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import router from '@/router';
import Analytics from '@/utils/analytics';

export default {
  components: {
    Autocomplete,
  },
  directives: { focus },
  data() {
    return {
      addressInput: '',
      products: [],
      isLoaded: false,
    };
  },
  watch: {
    '$store.state.search.products': function () {
      this.isLoaded = true;
    },
  },
  created() {
    this.isLoaded = this.$store.state.search.products?.length;
  },
  methods: {
    ...mapActions(['setSearchString']),
    search(input) {
      this.addressInput = input;
      const { products } = this.$store.state.search;
      this.$store.commit('setSearchString', input);
      if (input.length < 1) {
        return [];
      }
      const userInputNormalized = this.formatSearchCriteria(input);

      const listProducts = products.filter((product) =>
        this.formatSearchCriteria(
          `${product.product.brand} ${
            product.product.size || product.product.submenu
          } ${product.product.description}`
        ).includes(userInputNormalized)
      );

      const formattedSearchResult = listProducts.map(
        (product) =>
          `${product.product.brand} ${
            product.product.size || product.product.submenu
          }`
      );
      return [...new Set(formattedSearchResult)];
    },
    formatSearchCriteria(input) {
      return input
        .toUpperCase()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 ]/gu, ' ');
    },
    lostFocus() {
      if (this.addressInput) {
        Analytics.search(this.addressInput);
      }
    },
    handleSubmit(evt) {
      const searchString = evt || this.addressInput;
      this.addressInput = '';
      this.$store.commit('setSearchString', searchString);
      this.$emit('onSearchComplete');
      Analytics.search(searchString);
      if (this.$route.path !== '/search') {
        router.navigateToPage(
          `/search?redirect=${encodeURIComponent(searchString)}`
        );
      }
    },
    cleanSearch() {
      if (this.addressInput === '') {
        this.$emit('onSearchComplete');
      }
      this.addressInput = '';
      this.$store.commit('setSearchString', '');
      this.$nextTick(() => {
        this.$refs.search.$el.querySelector('input').focus();
      });
    },
  },
};
</script>

<style scoped>
:deep(.spinner-border) {
  border-color: var(--light);
  border-right-color: transparent;
}

.search {
  background-color: var(--dark);
}

:deep(.autocomplete-input) {
  background-image: none;
}

:deep(.autocomplete-result-list) {
  margin-top: 8px;
  background-color: var(--light);
  z-index: 20 !important; /* override 3rd party style */
}

:deep(.autocomplete-result-list li) {
  padding-top: 10px;
  font-size: var(--fs-12);
  font-weight: 300;
}

:deep(.autocomplete-result) {
  text-align: left;
  cursor: default;
  padding: 12px;
  background-image: none;
}

:deep(.autocomplete) {
  display: flex;
  position: relative;
}

.search :deep(input) {
  background-color: transparent;
  border: 0;
  color: var(--light);
  font-size: var(--fs-16);
  font-weight: 200;
  padding: 0 5px 3px 10px;
}

::placeholder {
  font-size: var(--fs-12);
  color: var(--dark);
}

.clean {
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>

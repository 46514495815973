const addToCart = (product, quantity) => {
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: '',
      value: product.price * quantity,
      items: [
        {
          item_id: product.sku,
          item_name: product.description,
          affiliation: window.location.href,
          coupon: '',
          discount: '',
          index: '',
          item_brand: product.brand,
          item_category: product.category,
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: product.price,
          quantity,
        },
      ],
    },
  });
};

const removeFromCart = (product, currency, quantity) => {
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency,
      value: product.price,
      items: [
        {
          item_id: product.sku,
          item_name: product.description,
          affiliation: '',
          coupon: '',
          discount: '',
          index: '',
          item_brand: product.brand,
          item_category: product.category,
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: product.price,
          quantity,
        },
      ],
    },
  });
};

const purchase = (products, purchaseData) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      ...purchaseData,
      items: products,
    },
  });
};

const search = (term) => {
  window.dataLayer.push({
    event: 'search',
    search_term: term,
  });
};

const viewCart = (cart, currency) => {
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      currency,
      value: cart.reduce(
        (a, { product }) => Number(a) + Number(product.price),
        0
      ),
      items: cart.map((product) => ({
        item_id: product.product.sku,
        item_name: product.product.description,
        affiliation: '',
        coupon: '',
        discount: '',
        index: '',
        item_brand: product.product.brand,
        item_category: product.product.category,
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: product.product.price,
        quantity: product.quantity,
      })),
    },
  });
};

const logEvent = (eventName, properties) => {
  const trackingProperties = { ...properties };
  if (eventName) {
    trackingProperties.event = eventName;
  }
  window.dataLayer.push(trackingProperties);
};

const addToWishlist = (product) => {
  window.dataLayer.push({
    event: 'add_to_wishlist',
    ecommerce: {
      currency: '',
      value: product.price,
      items: [
        {
          item_id: product.sku,
          item_name: product.description,
          affiliation: window.location.href,
          coupon: '',
          discount: '',
          index: '',
          item_brand: product.brand,
          item_category: product.category,
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: product.price,
          quantity: '',
        },
      ],
    },
  });
};

const removeFromWishlist = (product) => {
  window.dataLayer.push({
    event: 'remove_from_wishlist',
    ecommerce: {
      currency: '',
      value: product.price,
      items: [
        {
          item_id: product.sku,
          item_name: product.description,
          affiliation: '',
          coupon: '',
          discount: '',
          index: '',
          item_brand: product.brand,
          item_category: product.category,
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: product.price,
          quantity: '',
        },
      ],
    },
  });
};

export default {
  addToCart,
  removeFromCart,
  logEvent,
  purchase,
  search,
  viewCart,
  addToWishlist,
  removeFromWishlist,
};

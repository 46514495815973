<template>
  <div
    class="cart-item"
    :class="{ 'swiped-left': swipedLeft }"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div
      class="product__img-container mr-2 d-flex justify-content-center align-items-center"
    >
      <ImgGeneral
        class="item-img"
        :alt-text="product.product.description"
        :url="product.product.imageUrl"
        :show-fallback-img="true"
        :show-loader-before-init="true"
      />
    </div>
    <div class="description mb-3">
      <h4
        translate="no"
        class="product__title mb-1 fs-12"
      >
        {{ product.product.brand }}
      </h4>
      <p
        translate="no"
        class="fs-10 fw-200"
      >
        {{ product.product.description }}
      </p>
      <p
        v-if="
          !hasProductPricePromotion() &&
          priceWithoutDiscountFlag &&
          product.product.priceWithoutDiscount &&
          Number(product.product.priceWithoutDiscount) > product.product.price
        "
        class="mb-0 fs-10 text-decoration-line-through text-mediumgrey"
      >
        {{ formatPrice(Number(product.product.priceWithoutDiscount)) }}
      </p>
      <p
        v-if="!isLoading"
        class="product__price"
      >
        {{ formatPrice(Number(product.product.price)) }}
      </p>
      <p
        v-if="!isLoading && product.product.totalPrice"
        class="mb-0 fs-10 text-mediumgrey opacity-50 relativebottom"
      >
        {{ $t('subtotal') }}
        {{ formatPrice(Number(product.product.totalPrice)) }}
      </p>
      <Skeleton
        v-if="isLoading"
        :height="'30px'"
        class="w-25"
      />
    </div>

    <div class="product__quantity-section d-flex align-items-center">
      <div>
        <ProductQtyController
          :quantity="product.quantity"
          :enable-order="true"
          :disabled="
            enableUserInteraction || product.product.quantityLimit === 0
          "
          :qty-limit="
            $store.state.client.clientProfileInfo.type === 'Stockist'
              ? quantityLimit
              : undefined
          "
          :qty-limit-alert="
            $store.state.client.clientProfileInfo.type === 'Stockist'
              ? () => showProductQtyLimitToast(product.product)
              : undefined
          "
          @qty-updated="productQuantityUpdated"
        />
        <p
          v-if="
            typeof product.product.quantityLimit === 'number' &&
            $store.state.client.clientProfileInfo.type === 'Stockist'
          "
          class="text-muted fs-10 text-center"
        >
          {{ getWarningProductLimitText(product.product) }}
        </p>
        <p
          v-if="!isLoading"
          class="fs-10 text-center remove-container remove-section"
          @click="removeItem"
        >
          <ImgGeneral
            :is-one-px-placeholder="true"
            :data-src="`${assetsPath}img/trash-grey.svg`"
            class="remove-icon"
          />
          <span class="remove-text"> {{ $t('combo.remove') }}</span>
        </p>
      </div>
      <div
        class="delete-button"
        @click="removeItem"
      >
        <div class="remove-icon-flex">
          <ImgGeneral
            :is-one-px-placeholder="true"
            :data-src="assetsPath + 'img/delete.svg'"
          />
          <span class="remove-text-white"> {{ $t('combo.remove') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductMixin from '@/components/mixins/ProductMixin';
import ImgGeneral from '@/components/shared/ImgGeneral';
import ProductQtyController from '@/components/product/ProductQtyController.vue';
import Skeleton from '@/components/shared/Skeleton.vue';
import Analytics from '@/utils/analytics';
import { isProductBlockedToAddCart } from '@/utils/pages';
import { getStorageBottler, getAssetsPath } from '@/utils/config';

export default {
  components: {
    ImgGeneral,
    ProductQtyController,
    Skeleton,
  },
  mixins: [ProductMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    cartItems: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enableOrder: true,
      sku: this.product.product.sku,
      isProductComboInCart: null,
      quantity: 1,
      unitCostFlag: false,
      unitCost: '',
      enableUserInteraction: false,
      priceWithoutDiscountFlag: false,
      startX: 0,
      currentX: 0,
      swipedLeft: false,
      assetsPath: getAssetsPath(),
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
    }),
    printAlt() {
      const descriptionProductLowerCase = this.product
        ? `${this.$t(
            'product'
          )} ${this.product.product.description.toLowerCase()}`
        : '';
      return descriptionProductLowerCase;
    },
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.unitCostFlag = bottlerStorage?.unitCost;
    this.unitCost = this.product.product.unitCost;
    this.quantity = this.product.product.quantity;
    this.quantityLimit = this.product.product.quantityLimit;
    this.definePrice(this.qty);
    this.priceWithoutDiscountFlag = bottlerStorage?.showPriceWithoutDiscount;
    this.isProductComboInCart = isProductBlockedToAddCart({
      productSku: this.sku,
      cart: this.cart,
    });
  },
  methods: {
    formatPrice(price) {
      let priceCheck = Number.isNaN(parseInt(price)) ? 0 : price;
      if (typeof priceCheck === 'number') {
        priceCheck = priceCheck.toFixed(2);
      }
      priceCheck = Number(priceCheck).toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB',
      });
      return priceCheck;
    },
    findIndexInCart() {
      return this.cart.findIndex(
        (p) =>
          p.product.sku === this.product.product.sku &&
          p.product.combo === this.product.product.combo &&
          p.product.familyId === this.product.product.familyId
      );
    },
    productQuantityUpdated(quantity) {
      const locationInCart = this.findIndexInCart();
      this.quantity = Math.round(quantity);
      const quantityUpdated =
        this.quantity - this.cart[locationInCart].quantity;
      // GA track add to cart
      if (this.quantity > this.cart[locationInCart].quantity) {
        Analytics.addToCart(this.cart[locationInCart].product, quantityUpdated);
      }
      // GA track remove from cart
      if (this.quantity < this.cart[locationInCart].quantity) {
        Analytics.removeFromCart(
          this.cart[locationInCart].product,
          this.$t('currency'),
          Math.abs(quantityUpdated)
        );
      }
      this.cart[locationInCart].quantity = this.quantity;
      if (this.quantity <= 0) {
        this.cartItems.splice(locationInCart, 1);
      }
      this.$emit('cbUpdatePrice');
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
      const distance = this.currentX - this.startX;
      if (distance < -50) {
        this.swipedLeft = true;
      } else {
        this.swipedLeft = false; // Reset the state if not fully swiped
      }
    },
    handleTouchEnd() {
      if (!this.swipedLeft) {
        // Reset position if not fully swiped
        this.swipedLeft = false;
      }
    },
    removeItem() {
      const locationInCart = this.findIndexInCart();
      if (locationInCart !== -1) {
        const quantityUpdated =
          this.quantity - this.cart[locationInCart].quantity;
        Analytics.removeFromCart(
          this.cart[locationInCart].product,
          this.$t('currency'),
          Math.abs(quantityUpdated)
        );
        this.cartItems.splice(locationInCart, 1);
      }
      this.$emit('cbUpdatePrice');
    },
  },
};
</script>

<style scoped>
.cart-item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: transform 0.3s ease;
}

.item-img {
  max-width: 80px;
  max-height: 200px;
}

.relativebottom {
  position: relative;
  margin-top: 15px;
  bottom: 2%;
  left: 0;
  margin: 0;
}

.remove-section {
  bottom: 5px;
  left: 0;
  width: 100%;
  margin-top: 0;
}

.description {
  position: relative;
  margin-top: 20px;
  transition: transform 0.3s ease;
}
.product__quantity-section {
  position: relative;
  margin-right: 5px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust this value as needed */
}
.text-muted {
  position: relative;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.product__img-container {
  margin-top: 10px;
  margin-bottom: 10px;
  border-right: 1px solid var(--light-grey);
  display: grid;
  gap: 4px;
  position: relative;
  transition: transform 0.3s ease;
}

.delete-button {
  display: none;
  background-color: #d03635;
  align-items: center;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 100%;
  width: 75%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: opacity 0.3s ease;
}

.remove-container {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remove-icon {
  margin-left: 20px;
  margin-right: 5px;
}

.remove-icon-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remove-text {
  text-decoration: underline;
  color: #6a6a6a;
  cursor: pointer;
}

.remove-text-white {
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
  font-size: 9px;
  text-align: center;
}

.swiped-left .cart-item {
  transform: translateX(-75px);
}
.swiped-left .product__img-container {
  transform: translateX(-75px);
}
.swiped-left .description {
  transform: translateX(-80px);
}
.swiped-left .product__quantity-section {
  margin-right: -30px;
  transform: translateX(-40px);
}

.swiped-left .text-muted {
  margin-right: -30px;
  transform: translateX(-40px);
}

.swiped-left .remove-container {
  margin-right: -30px;
  transform: translateX(-40px);
}

.swiped-left .delete-button {
  display: grid;
  transform: translateX(10px);
}

@media only screen and (min-width: 850px) {
  .relativebottom {
    bottom: 4%;
  }
}

@media only screen and (min-width: 850px) {
  .cart-item {
    grid-template-columns: 1fr 2fr 1fr;
  }
  .item-img {
    max-width: 200px;
  }
}
</style>

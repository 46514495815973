<template>
  <div
    id="product-item"
    class="mt-3 d-flex"
  >
    <div
      class="card-body d-flex flex-column justify-content-between mb-3 p-0 position-relative"
    >
      <div class="w-100">
        <div class="product__img-container w-100 text-center">
          <div class="position-relative d-block">
            <ImgGeneral
              :alt="printAlt"
              class="responsive img-product pt-2"
              :url="product.product.imageUrl"
              :show-fallback-img="true"
              :show-loader-before-init="true"
            />
            <ImgGeneral
              v-if="product.product.iconBannerUrl"
              :alt="$t('ariaLabel.promotionIcon')"
              class="position-absolute promotion-overlay-image"
              :url="product.product.iconBannerUrl"
              :is-one-px-placeholder="true"
            />
            <hr class="m-0" />
            <ProductTag v-if="showTag && !isProductComboInCart" />
            <ProductTag
              v-if="showTag && isProductComboInCart"
              :label-title="$t('combo.tag')"
              :bg-color="'warning'"
            />
          </div>
        </div>
        <div class="px-2 pt-2">
          <div class="d-flex mb-2">
            <HelpButton
              v-if="showTag && isProductComboInCart"
              :help-description="$t('combo.helpCombo')"
              :class="'mr-2'"
              :sku="String(product.product.sku)"
            />
            <h4
              translate="no"
              class="product__title my-0 fs-12 text-break flex-fill"
            >
              {{ product.product.description }}
            </h4>
            <div class="fs-20 ml-2 heart-icon text-center">
              <b-icon
                v-if="isCallingFavorite === false"
                class="h4 m-0"
                :icon="isFavorite ? 'heart-fill' : 'heart'"
                @click="favoriteClick()"
              />
              <b-spinner
                v-else
                label="Loading..."
                class="mb-1"
              />
            </div>
          </div>
          <b-container fluid>
            <b-row align-v="center">
              <b-col class="pl-0">
                <table v-if="product.product.pricePromotions">
                  <tbody>
                    <div
                      v-for="promotion in product.product.pricePromotions"
                      :key="promotion.minimumQuantity"
                    >
                      <p
                        v-if="
                          Number(promotion.discountPercentage) > 0 &&
                          qty >= promotion.minimumQuantity &&
                          product.product.isPromotionType
                        "
                        class="product__discount-value"
                        :aria-label="
                          $t('ariaLabel.discountLabel', {
                            discount: promotion.discountPercentage,
                          })
                        "
                      >
                        -{{ promotion.discountPercentage }}%
                      </p>
                      <tr
                        class="product__minimum-value text-primary"
                        :class="[
                          price == promotion.price
                            ? 'opacity-100'
                            : 'opacity-25',
                        ]"
                      >
                        <td>
                          <span
                            class="product__minimum-value-unit fs-10 fw-200"
                          >
                            {{ promotion.minimumQuantity }} {{ $t('box') }}
                          </span>
                        </td>
                        <td>
                          <span
                            class="product__minimum-value-price pl-1 pr-1 fs-12 fw-200"
                          >
                            {{ formatPrice(Number(promotion.price)) }}
                          </span>
                        </td>
                        <td>
                          <span class="fs-10">
                            {{ $t('each') }}
                          </span>
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
                <div v-if="!hasProductPricePromotion()">
                  <p
                    v-if="
                      priceWithoutDiscountFlag &&
                      product.product.priceWithoutDiscount &&
                      Number(product.product.priceWithoutDiscount) >
                        product.product.price
                    "
                    class="mb-0 fs-10 text-decoration-line-through text-mediumgrey"
                  >
                    {{
                      formatPrice(Number(product.product.priceWithoutDiscount))
                    }}
                  </p>
                  <p
                    v-if="product.product.price"
                    :style="{ marginBottom: unitCost ? 'revert' : '0' }"
                    class="product__price"
                  >
                    {{ formatPrice(Number(product.product.price)) }}
                  </p>
                </div>
              </b-col>

              <div sm="auto px-0">
                <b-icon
                  v-if="product.product.isRecommended"
                  class="h4 m-0"
                  icon="star-fill"
                  variant="warning"
                />
              </div>
            </b-row>
          </b-container>
          <ProductPriceDetails
            :unit-cost="unitCost"
            :suggested-sale="product.product.suggestedSale"
            class="mt-4"
          />
        </div>
      </div>
      <b-button
        v-if="product.product.comboDetails"
        variant="primary"
        class="mx-2"
        @click="showPromotionDetails"
      >
        {{ $t('viewPromotionDetails') }}
      </b-button>
      <div
        v-if="!isProductComboInCart"
        class="w-100 px-2 pb-2"
      >
        <ProductQtyController
          :define-price="definePrice"
          :enable-order="enableOrder"
          :quantity="quantity"
          :disabled="
            enableUserInteraction || product.product.quantityLimit === 0
          "
          :qty-limit="
            $store.state.client.clientProfileInfo.type === 'Stockist'
              ? product.product.quantityLimit
              : undefined
          "
          :qty-limit-alert="
            $store.state.client.clientProfileInfo.type === 'Stockist'
              ? () => showProductQtyLimitToast(product.product)
              : undefined
          "
          @qty-updated="qtyUpdated"
        />
        <p
          v-if="
            typeof product.product.quantityLimit === 'number' &&
            $store.state.client.clientProfileInfo.type === 'Stockist'
          "
          class="text-muted fs-10 text-center t-4"
        >
          {{ getWarningProductLimitText(product.product) }}
        </p>
        <button-add-cart
          :disabled="product.product.quantityLimit === 0"
          :enable-user-interaction="enableUserInteraction"
          @click-add-to-cart="addToCart()"
        />
      </div>
      <div
        v-if="isProductComboInCart"
        class="align-self-end w-100 p-2"
      >
        <router-link
          class="position-relative btn btn-outline d-block"
          :to="`/combo-quantity/${isProductComboInCart.product.combo}`"
        >
          {{ $t('accessButton') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';
import apiProductsFavorites from '@/api/productsFavorites';
import ProductPriceDetails from '@/components/product/ProductPriceDetails.vue';
import ProductMixin from '@/components/mixins/ProductMixin';
import ProductTag from '@/components/product/ProductTag.vue';
import Analytics from '@/utils/analytics';
import apiSendEvent from '@/api/event';
import ButtonAddCart from '@/components/product/ButtonAddCart.vue';
import ImgGeneral from '@/components/shared/ImgGeneral';
import HelpButton from '@/components/shared/HelpButton';
import ProductQtyController from '@/components/product/ProductQtyController.vue';
import {
  isProductBlockedToAddCart,
  openModalNotification,
  openModalNotificationAction,
} from '@/utils/pages';
import { getStorageBottler } from '@/utils/config';

export default {
  components: {
    ProductPriceDetails,
    ProductTag,
    ButtonAddCart,
    ImgGeneral,
    ProductQtyController,
    HelpButton,
  },
  mixins: [ProductMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    promotionNav: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      enableOrder: true,
      sku: this.product.product.sku,
      isProductComboInCart: null,
      quantity: 1,
      unitCostFlag: false,
      unitCost: '',
      enableUserInteraction: false,
      priceWithoutDiscountFlag: false,
      isFavorite: false,
      isCallingFavorite: false,
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
    }),
    printAlt() {
      const descriptionProductLowerCase = this.product
        ? `${this.$t(
            'product'
          )} ${this.product.product.description.toLowerCase()}`
        : '';
      return descriptionProductLowerCase;
    },
  },
  watch: {
    '$store.state.cart.simulatedPriceLoading':
      function onSimulatedPriceLoadingUpdate(newVal) {
        if (!newVal) {
          this.enableUserInteraction = false;
        }
      },
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.unitCostFlag = bottlerStorage?.unitCost;
    this.unitCost = this.product.product.unitCost;
    this.quantity = this.product.product.quantity;
    this.isFavorite = this.product.product.isFavorite;
    this.priceWithoutDiscountFlag = bottlerStorage?.showPriceWithoutDiscount;
    this.definePrice(this.qty);
    this.isProductComboInCart = isProductBlockedToAddCart({
      productSku: this.sku,
      cart: this.cart,
    });
  },
  methods: {
    addToCart() {
      this.enableUserInteraction = true;
      if (this.qty > 0 && !Number.isNaN(this.qty)) {
        this.enableOrder = true;
        const { product } = this.product;

        if (product.quantityLimit > 0) {
          this.qty = this.getQtyLimitAllowedAddToCart(product);
        }

        this.$emit('add-to-cart', { product, quantity: Number(this.qty) });
        Analytics.addToCart(product, Number(this.qty));
        apiSendEvent.postEvent('AddToCartv2', {
          addtocart: true,
          products: [{ sku: this.sku, quantity: Number(this.qty) }],
        });
        this.quantity = 1;
        this.qty = 1;
      } else {
        this.enableOrder = false;
        this.quantity = 1;
        this.qty = 1;
      }
    },
    qtyUpdated(quantity) {
      this.quantity = quantity;

      if (
        this.unitCostFlag &&
        this.product.product.pricePromotions?.length > 0
      ) {
        const sortedList = this.product.product.pricePromotions.sort(
          (a, b) => a.minimumQuantity - b.minimumQuantity
        );

        this.unitCost = sortedList
          .filter((range) => this.quantity >= range.minimumQuantity)
          .map((range) => range)
          .pop().unitCost;
      }
    },
    favoriteClick() {
      if (!this.isCallingFavorite) {
        this.isCallingFavorite = true;

        const initialFavoriteValue = this.isFavorite;
        this.isFavorite = !initialFavoriteValue;

        // Update local Favorite store as product is only called once in the app
        this.$store.dispatch('setFavorite', {
          sku: this.sku,
          isFavorite: this.isFavorite,
        });
        apiProductsFavorites
          .setProductsFavorites({ sku: this.sku, isFavorite: this.isFavorite })
          .then((response) => {
            if (response === false) {
              this.isFavorite = initialFavoriteValue;
              this.$store.dispatch('setFavorite', {
                sku: this.sku,
                isFavorite: this.isFavorite,
              });
            }
            this.isCallingFavorite = false;
          });

        if (this.isFavorite) {
          Analytics.addToWishlist(this.product.product);
        } else {
          Analytics.removeFromWishlist(this.product.product);
        }
      }
    },
    showPromotionDetails() {
      const h = this.$createElement;
      const modalData = {
        headerBgVariant: 'primary',
        title: [
          h(
            'span',
            {
              style: { color: 'green', fontWeight: 500 },
            },
            ` ${this.$t('combo.quantityComboTitle')}`
          ),
        ],
        description: this.product.product.comboDetails.description
          .split('\n')
          .map((row) => h('p', [row]))
          .concat([
            h('p', this.$t('combo.quantityComboTitle')),
            h('p', this.$t('combo.quantityComboDescription')),
          ]),
        okTitle: this.$t('ok'),
      };
      if (this.promotionNav) {
        openModalNotificationAction(this.$bvModal, {
          ...modalData,

          cancelTitle: this.$t('pageTitles.promotions'),
        }).then((value) => {
          if (value === false) {
            router.navigateToPage('/promotions');
          }
        });
      } else {
        openModalNotification(this.$bvModal, {
          ...modalData,
        });
      }
    },
  },
};
</script>
